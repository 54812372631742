import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { BehaviorSubject, Observable } from "rxjs";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Push, PushObject, PushOptions } from "@ionic-native/push/ngx";

// import { Device } from "@ionic-native/device/ngx";
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { GlobalService } from "./providers/global/global.service";
import { HttpService } from "../../src/app/providers/httpService/http.service";
//import { INotificationPayload } from "../../plugins/cordova-plugin-fcm-with-dependecy-updated/typings";
import { LanguageService } from "./providers/languages/language.service";
import { PushNotificationService } from "../../src/app/providers/pushNotification/push-notification.service";
import { ResultsService } from "./providers/results/results.service";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StaticDataService } from "./providers/staticData/static-data.service";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Storage } from "@ionic/storage";
import { SwUpdate } from "@angular/service-worker";
import { TranslateFromApiService } from "./providers/translate/translate-from-api.service";
import { TranslateService } from "@ngx-translate/core";
import { UserData } from "./providers/user-data";
import { forkJoin } from "rxjs";
import { CommonService } from "./providers/commonService/common-service.service";
import { PollModalPage } from "./pages/inside-app/poll-modal/poll-modal.page";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  public appPages = [];
  public loggedIn = false;
  public dark = false;
  public excludedNotifications: any = [];
  public sports: any;
  public events: any;
  public postType;
  public postCategory;
  public sportScreen;
  public lang = null;
  public isActiveTousLesFoot = false;
  public isActiveFootInternational = false;
  public isActiveDivers = false;
  public isActiveMercato = false;
  public isActiveInternational = false;
  private authenticated$: BehaviorSubject<boolean>;
  public lng;
  public pushes: any = [];
  public user;
  public userConnected;
  public hasPermission: boolean;
  public token: string;
 // public pushPayload: INotificationPayload;
  public poll: any = null;
  public state;
  public disabled = false;
  constructor(
    private storage: Storage,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    public screenOrientation: ScreenOrientation,
    public languagesService: LanguageService,
    public globalService: GlobalService,
    public modalCtrl: ModalController,
    public resultsService: ResultsService,
    private route: ActivatedRoute,
    public staticDataService: StaticDataService,
    public menuCtrl: MenuController,
    public translateFromApiService: TranslateFromApiService,
    //private fcm: FCM,
    public alertCtrl: AlertController,
    public push: Push,
    // public device: Device,
    public pushNotificationService: PushNotificationService,
    public httpService: HttpService,
    private readonly commonService: CommonService,
    public modalController: ModalController,


  ) {
    this.initializeApp();
    // this.loadPoll();  

  }

  async ngOnInit() {
  
    this.globalService.getKey("SELECTED_LANGUAGE").then((lngLocal) => {
      this.excludedNotifications = this.translateFromApiService.translate(
        lngLocal,
        this.excludedNotifications
      );
      if (lngLocal !== null) {
        this.languagesService.setLanguage(lngLocal);
      }
    });
   
    await this.globalService.setKey(
      "notifications",
      this.excludedNotifications
    );

    this.languagesService.currentLng$.subscribe(async (currentLng) => {
      this.lng = currentLng;
      if (currentLng !== null) {
        this.excludedNotifications = this.translateFromApiService.translate(
          this.lng,
          this.excludedNotifications
        );
        await this.globalService.setKey(
          "notifications",
          this.excludedNotifications
        );
      }
    });

    this.resultsService.currentPostType$.subscribe((currentPostType) => {
      this.postType = currentPostType;
    });

    this.resultsService.currentPostCategory$.subscribe(
      (currentPostCategory) => {
        this.postCategory = currentPostCategory;
      }
    );

    // await this.resultsService.loadingDataSports();
    this.appPages = [];
    this.resultsService.currentAllSports$.subscribe((sports) => {
      this.globalService.getKey("SELECTED_LANGUAGE").then((lng) => {
        this.lang = lng;
      });

      const appPages = this.staticDataService.settings.appPages.slice(0);
      let i = 2;
      if (sports !== null) {
        this.globalService.setKey("sports", sports);
        if (sports.length > 0 && appPages.length === 6) {
          sports.forEach((sport) => {
            if (sport.showInMenu) {
              const sportPage: any = {
                open: false,
                title: sport.name,
                imgFromUrl:
                  this.staticDataService.settings.server + sport.picture,
                events: sport.events,
                showEvents: sport.showEvents,
                id: sport.postTypeId,
              };
              appPages.splice(i, 0, sportPage);
              i++;
            }
          });
        }
      }

      this.appPages = appPages;
      this.sports = sports;
    });

    this.resultsService.currentEvents$.subscribe((events) => {
      this.events = events;
    });

    const postType = this.route.snapshot.paramMap.get("postType");
    this.postType = postType;
    const postCategory = this.route.snapshot.paramMap.get("postCategory");
    this.postCategory = postCategory;
    const sportScreen = this.route.snapshot.paramMap.get("sportScreen");
    this.sportScreen = sportScreen;

    this.userData.currentUserData$.subscribe(() => {
      this.loggedIn = true;
    });

    this.swUpdate.available.subscribe(async (res) => {
      const toast = await this.toastCtrl.create({
        message: "Update available!",
        position: "bottom",
        buttons: [
          {
            role: "cancel",
            text: "Reload",
          },
        ],
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });

    this.state = await this.globalService.getKey("state");
    // if (this.state === 'INACTIVE') {
    //   this.disabled = true
    //   // this.menuCtrl.enable(false); // when unauthenticated pages
    //   console.log('state', this.state);
    //   // alert when state inactive 
    //   const alert = await this.alertCtrl.create({
    //     cssClass: "model-style",
    //     // header: data.title,
    //     message: this.translate.instant("ACCOUNTRECHARGE"),
    //     buttons: [
    //       {
    //         text: this.translate.instant("OK"),
    //         role: "cancel",
    //         cssClass: "secondary",
    //         handler: (blah) => {
    //           console.log("Confirm Cancel");
    //         },
    //       },

    //     ],
    //   });
    //   await alert.present();
    // } else if (this.state === 'free') {
    //   this.disabled = false
    // }
  }

  public getAuthentication(): Observable<boolean> {
    return this.authenticated$.asObservable();
  }

  toggleMenu() {
    this.menuCtrl.toggle();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is("android") || this.platform.is("ios")) {
        this.initFirebaseNotification();
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );
      }
      // const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      // console.log('lng in initializeApp', lng);

      // if (lng) {
      //   this.lang = lng;
      //   this.languagesService.setLanguage(lng);
      // }

      setTimeout(() => {
        this.splashScreen.hide();
        this.languagesService.setInitialAppLanguage();

       /* if (this.platform.is("android") || this.platform.is("ios")) {
          this.setupFCM();
          this.initPushNotification();
          // subscribe to a topic
          // this.fcm.subscribeToTopic('Deals');

          // get FCM token
          this.fcm.getToken().then((token) => {
            console.log(token);
          });

          // refresh the FCM token
          this.fcm.onTokenRefresh().subscribe((token) => {
            console.log(token);
          });
        }*/

        // refresh the FCM token
      /*  if (this.platform.is("android") || this.platform.is("ios")) {
          this.fcm.onTokenRefresh().subscribe((token) => {
            console.log(token);
          });
        }*/
      }, 1000);
    });
  }

  onChangePostType(postType) {
    this.clearActiveStatuses();
    console.log("postType", postType);
    this.resultsService.changePostType(postType);
  }

  goToTousLesFoot() {
    this.clearActiveStatuses();
    this.isActiveTousLesFoot = !this.isActiveTousLesFoot;
    this.router.navigateByUrl(this.staticDataService.settings.newsUrl + "1", {
      queryParams: { chekNotEvent: 1 },
    });
  }

  goToFootInternational() {
    this.clearActiveStatuses();
    this.isActiveFootInternational = !this.isActiveFootInternational;
    this.router.navigateByUrl(this.staticDataService.settings.newsUrl + "1/1", {
      queryParams: { chekNotEvent: 1 },
    });
  }

  goToDivers() {
    this.clearActiveStatuses();
    this.isActiveDivers = !this.isActiveDivers;
    this.router.navigateByUrl(
      this.staticDataService.settings.newsUrl + "1/11",
      { queryParams: { chekNotEvent: 1 } }
    );
  }

  goToMercato() {
    this.clearActiveStatuses();
    this.isActiveMercato = !this.isActiveMercato;
    this.router.navigateByUrl(
      this.staticDataService.settings.newsUrl + "1/12",
      { queryParams: { chekNotEvent: 1 } }
    );
  }

  goToInternational() {
    this.clearActiveStatuses();
    this.isActiveInternational = !this.isActiveInternational;
    this.router.navigateByUrl(
      this.staticDataService.settings.newsUrl + "1/33",
      { queryParams: { chekNotEvent: 1 } }
    );
  }

  logout() {
    this.userData.logout().then(() => {
      this.clearActiveStatuses();
      return this.router.navigateByUrl("");
    });
  }

  terms() {
    this.router.navigateByUrl("/terms");
  }

  clearActiveStatuses() {
    console.log("clearActiveStatuses");
    this.isActiveFootInternational = false;
    this.isActiveTousLesFoot = false;
    this.isActiveDivers = false;
    this.isActiveMercato = false;
    this.isActiveInternational = false;
  }

  initPushNotification() {
    console.log("initPushNotification");

    // to check if we have permission
    this.push.hasPermission().then((res: any) => {
      console.log("initPushNotification,  res", res);

      if (res.isEnabled) {
        console.log("We have permission to send push notifications");
      } else {
        console.log("We don't have permission to send push notifications");
      }
    });

    // to initialize push notifications

    const options: PushOptions = {
      android: {
        // senderID: '87114322546'
      },
      ios: {
        alert: "true",
        badge: true,
        sound: "false",
      },
      windows: {},
      browser: {
        pushServiceURL: "http://push.api.phonegap.com/v1/push",
      },
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject.on("notification").subscribe(async (notification: any) => {
      console.log("Received a notification", notification);
    });

    pushObject.on("registration").subscribe((registration: any) => {
      console.log("Device registered", registration);
    });

    pushObject
      .on("error")
      .subscribe((error) => console.error("Error with Push plugin", error));
  }

  redirect(data) {
    let url = "/app/tabs/news";
    if (data.state === "app.post") {
      url = "/app/tabs/news/news-detail/" + data.id;
    } else if (data.state === "app.video") {
      url = "/app/tabs/videos/video-player/" + data.id;
    } else if (data.state === "app.photos") {
      url = "/app/tabs/photos/gallery/" + data.id;
    } else if (data.state === "app.match") {
      url = "/app/tabs/matches/" + data.id;
    }
    console.log("url", url);
    return url;
  }

  initFirebaseNotification() {
    console.log("initFirebaseNotification");
  /*  this.fcm.onNotification().subscribe(async (data) => {
      console.log("initFirebaseNotification - onNotification: ", data);
      if (data.wasTapped) {
        console.log("Received in background");
        this.router.navigateByUrl(this.redirect(data));
      } else {
        console.log("Received in foreground");
        // alert when push notification in foreground
        const alert = await this.alertCtrl.create({
          cssClass: "model-style",
          header: data.title,
          message: data.body,
          buttons: [
            {
              text: this.translate.instant("common.CANCEL"),
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                console.log("Confirm Cancel");
              },
            },
            {
              text: this.translate.instant("common.VIEW"),
              handler: () => {
                this.router.navigateByUrl(this.redirect(data));
              },
            },
          ],
        });
        await alert.present();
      }
      this.globalService.getKey("hasLoggedIn").then(async (userConnected) => {
        this.userConnected = userConnected;
        if (this.userConnected === true) {
          // this.router.navigateByUrl(this.redirect(data));
        } else {
          console.log("Received in foreground");
          const alert = await this.alertCtrl.create({
            cssClass: "model-style",
            header: data.title,
            message: data.body,
            buttons: [
              {
                text: this.translate.instant("common.CANCEL"),
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  console.log("Confirm Cancel");
                },
              },
              {
                text: this.translate.instant("common.VIEW"),
                handler: () => {
                  this.router.navigateByUrl(this.redirect(data));
                },
              },
            ],
          });
          await alert.present();
        }
        this.globalService.getKey("hasLoggedIn").then((userConnected2) => {
          this.userConnected = userConnected2;
          if (this.userConnected === true) {
            // this.router.navigateByUrl(this.redirect(data));
          } else {
            this.router.navigate(["/signup"]);
            this.globalService.setKey("redirectTo", this.redirect(data));
          }
        });
      });
    });

    /*this.fcm.onTokenRefresh().subscribe(async (token) => {
      console.log("onTokenRefresh - token: ", token);
    });*/
  }

  /*private async setupFCM() {
    await this.platform.ready();
    console.log("FCM setup started");

    if (!this.platform.is("cordova")) {
      return;
    }
    console.log("In cordova platform");

    console.log("Subscribing to token updates");
    this.fcm.onTokenRefresh().subscribe((newToken) => {
      this.token = newToken;
      console.log("onTokenRefresh received event with: ", newToken);
    });

    console.log("Subscribing to new notifications");
    this.fcm.onNotification().subscribe((payload) => {
      this.pushPayload = payload;
      console.log("onNotification received event with: ", payload);
    });

    this.hasPermission = await this.fcm.requestPushPermission();
    console.log("requestPushPermission result: ", this.hasPermission);

    this.token = await this.fcm.getToken();
    console.log("getToken result: ", this.token);

    this.pushPayload = await this.fcm.getInitialPushPayload();
    console.log("getInitialPushPayload result: ", this.pushPayload);
  }

  public get pushPayloadString() {
    return JSON.stringify(this.pushPayload, null, 4);
  }

  async loadPoll() {
    this.poll = null;


    const reqPolls = await this.httpService.get("pollOfDay");
    forkJoin([reqPolls]).subscribe(
      async (data: any) => {
        const results = data[0];
        this.poll = results[0];

        console.log("results Poll of Day", results.length, data, data[0].length);

        if (data[0].length === 0) {

          const modal = await this.modalController.create({
            component: PollModalPage,
            componentProps: {
              poll: this.poll
            }
          });
          await modal.present();
        }

      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }        this.commonService.handleError(error);
      }
    );
  }*/
  
  ngOnDestroy() {
    console.log('ngOnDestroy');
    
    this.modalController.dismiss();
  }
}
