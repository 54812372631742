import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { StaticDataService } from "./staticData/static-data.service";
import { Storage } from "@ionic/storage";
import { HttpService } from "./httpService/http.service";

@Injectable({
  providedIn: "root",
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = "hasLoggedIn";
  HAS_SEEN_TUTORIAL = "hasSeenTutorial";
  public userData$ = new BehaviorSubject<any>(null);
  public currentUserData$ = this.userData$.asObservable();

  constructor(
    public storage: Storage,
    private menu: MenuController,
    private readonly http: HttpClient,
    private readonly staticDataService: StaticDataService,
    public httpService: HttpService
  ) {
    Promise.all([this.isLoggedIn(), this.getUsername()]).then((value) => {
      if (value[0] === true) {
        this.changeUserData(value[1]);
      }
    });
  }

  register(registerData: {}, language) {
    return this.http.post(
      this.staticDataService.settings.server +
        // language +
        // "/" +
        this.staticDataService.settings.apiURL +
        // this.staticDataService.settings.apiVersion +
        "login_check",
      registerData
    );
  }

  findUser(userData: {}) {
    return this.http.post(
      this.staticDataService.settings.server +
    
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      "subscription/find",
      userData
    );
  }

  deleteSubscription(userData: {}, language) {
  
    return this.httpService.putuser(
        this.staticDataService.settings.server +
        language +
        "/" +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        "subscription/" + userData
      );

  }

  checkToken(token: string, language) {
    return this.http.post(
      this.staticDataService.settings.server +
        language +
        "/" +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        "checkToken",
      { token }
    );
  }

  changeUserData(data) {
    this.userData$.next(data);
  }

  hasFavorite(sessionName: string): boolean {
    return this.favorites.indexOf(sessionName) > -1;
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(username: string): Promise<any> {
    try {
      this.storage.remove("sports");
    } catch (error) {
      console.log("no sport founded");
    }
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      this.changeUserData(username);
    });
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
    });
  }

  async logout(): Promise<any> {
    await this.menu.close();
    await this.menu.enable(false);
    await this.changeUserData(null);
    this.storage.get("SELECTED_LANGUAGE").then(async (lastLng) => {
      this.storage.remove("user");
      this.storage.remove("token");
      this.storage.remove("hasLoggedIn");
      this.storage.remove("sports");
      console.log("logout", lastLng);
      await this.storage.set("SELECTED_LANGUAGE", lastLng);
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set("username", username);
  }

  getUsername(): Promise<string> {
    return this.storage.get("username").then((value) => {
      return value;
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }

  createNewUser(postData: {}, language) {
    // tslint:disable-next-line:max-line-length
    const url =
      this.staticDataService.settings.server +
      language +
      "/" +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      "register";
    return this.http.post(url, postData);
  }

  confirmNewUser(postData: {}) {
    // tslint:disable-next-line:max-line-length
    const url =
      this.staticDataService.settings.server +
      "fr/" +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      "authVerifPin";
    return this.http.post(url, postData);
  }

  getCountries() {
    return new Promise((resolve) => {
      this.http
        .get(
          this.staticDataService.settings.server +
            "fr/" +
            this.staticDataService.settings.apiURL +
            "countries"
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
}
