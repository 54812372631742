import { BehaviorSubject, forkJoin } from "rxjs";
import { Subscription, interval } from "rxjs";

import { CommonService } from "./../commonService/common-service.service";
import { GlobalService } from "../global/global.service";
import { HttpService } from "../httpService/http.service";
import { Injectable } from "@angular/core";
import { LanguageService } from "../languages/language.service";
import { StaticDataService } from "../staticData/static-data.service";
import { TranslateFromApiService } from "../translate/translate-from-api.service";

@Injectable({
  providedIn: "root",
})
export class ResultsService {
  public subscription: Subscription;

  public postType$ = new BehaviorSubject<any>(null);
  public currentPostType$ = this.postType$.asObservable();

  public postCategory$ = new BehaviorSubject<any>(null);
  public currentPostCategory$ = this.postCategory$.asObservable();

  public sportScreen$ = new BehaviorSubject<any>("result");
  public currentsportScreen$ = this.sportScreen$.asObservable();

  public sports$ = new BehaviorSubject<any>(null);
  public currentAllSports$ = this.sports$.asObservable();

  public events$ = new BehaviorSubject<any>(null);
  public currentEvents$ = this.events$.asObservable();

  public ranking$ = new BehaviorSubject<any>(null);
  public currentRanking$ = this.ranking$.asObservable();

  public results$ = new BehaviorSubject<any>(null);
  public currentResults$ = this.results$.asObservable();

  public calendar$ = new BehaviorSubject<any>(null);
  public currentCalendar$ = this.calendar$.asObservable();

  public live$ = new BehaviorSubject<any>(null);
  public currentLive$ = this.live$.asObservable();

  public imeOutForLive = 10;
  public sports: any;
  public events: any;
  public erreur = false;
  public tablesByEventBody = {
    idsportEvent: null,
    width: "50",
  };
  public liveInterval;
  public lng;
  public resultsList: any = [];
  public matchesList: any = [];
  public liveList: any = [];

  constructor(
    private translateFromApiService: TranslateFromApiService,
    public globalService: GlobalService,
    public httpService: HttpService,
    public languagesService: LanguageService,
    private readonly staticDataService: StaticDataService,
    private readonly commonService: CommonService
  ) {
    this.languagesService.currentLng$.subscribe((currentLng) => {
      this.lng = currentLng;
      console.log("this.sports$.value", this.sports$.value);
      console.log("currentLng", currentLng);
      if (currentLng !== null && this.sports$.value !== null) {
        this.loadingDataSports();
      }
    });
  }

  async changePostType(data) {
    this.resultsList = [];
    this.matchesList = [];
    this.liveList = [];
    this.postType$.next(data);
    await this.loadingEventsBySportId();
  }

  changePostCategory(data) {
    this.resultsList = [];
    this.matchesList = [];
    this.liveList = [];
    this.postCategory$.next(data);
  }

  async loadingDataSports() {
    const sports = await this.globalService.getKey("sports");
    if (sports) {
      await this.globalService
        .getKey("SELECTED_LANGUAGE")
        .then((lngLocal: string) => {
          this.sports = this.translateFromApiService.translate(
            lngLocal,
            sports
          );
        });
      const sport = sports[0];
      this.changeAllSports(this.sports);
      await this.changePostType(sport.postTypeId);
    } else {
      // this.globalService.showLoader('Loading ...');
      const reqSports = await this.httpService.post("sports", { width: 50 });
      await forkJoin([reqSports]).subscribe(
        async (results: any) => {
          await this.globalService
            .getKey("SELECTED_LANGUAGE")
            .then((lngLocal: string) => {
              this.sports = this.translateFromApiService.translate(
                lngLocal,
                results[0]
              );
            });
          this.sports = results[0];
          const sport = this.sports[0];
          await this.changePostType(sport.postTypeId);
          await this.globalService.setKey("sports", results[0]);
          await this.changeAllSports(results[0]);
          await this.changePostType(results[0][0].postTypeId);
        },
        async (error) => {
          if (!this.erreur) {
            if (error.status === 401) {
              this.globalService.showSessionExpiredAlertAndLogOut();
              this.erreur = true;
              await this.globalService.dismissLoader();
            }
            this.commonService.handleError(error);
            await this.globalService.dismissLoader();
          }
        }
      );
    }
  }

  changeAllSports(data) {
    this.sports$.next(data);
  }

  changeSportScreen(data) {
    this.sportScreen$.next(data);
  }

  async loadingEventsBySportId() {
    try {
      console.log(
        "this.postType$.getValue()",
        this.postType$.getValue(),
        "this.postCategory$.getValue()",
        this.postCategory$.getValue()
      );

      const allSports = await this.globalService.getKey("sports");
      if (allSports !== null) {
        const sports = allSports.filter(
          (x) => x.postTypeId === Number(this.postType$.getValue())
        );
        if (sports.length > 0) {
          const sport = sports[0];
          let allEvents = sport.events;
          await this.globalService
            .getKey("SELECTED_LANGUAGE")
            .then((lngLocal: string) => {
              allEvents = this.translateFromApiService.translate(
                lngLocal,
                allEvents
              );
            });
          this.changeEventsBySportId(allEvents);
          let event = null;
          if (this.postCategory$.getValue() !== null) {
            const events = allEvents.filter(
              (x) => x.postCategoryId === Number(this.postCategory$.getValue())
            );
            event = events[0];
            console.log("event", event, this.postCategory$.getValue());
          } else {
            event = allEvents[0];
            this.changePostCategory(event.postCategoryId);
          }
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  }

  changeEventsBySportId(data) {
    this.events$.next(data);
  }

  async loadingRankingByEventId() {
    try {
      const allSports = await this.globalService.getKey("sports");
      const sports = allSports.filter(
        (x) => x.postTypeId === Number(this.postType$.getValue())
      );
      let sport;
      if (sports.length > 0) {
        sport = sports[0];
      } else {
        sport = allSports[0];
      }
      const allEvents = sport.events;
      const events = allEvents.filter(
        (x) => x.postCategoryId === Number(this.postCategory$.getValue())
      );
      let sportEvent;
      if (events.length > 0) {
        sportEvent = events[0];
      } else {
        sportEvent = allEvents[0];
      }
      console.log("event", sportEvent, this.postCategory$.getValue());

      this.tablesByEventBody.idsportEvent = sportEvent.id;
      const reqRanking = await this.httpService.post(
        "tablesByEvent",
        this.tablesByEventBody
      );
      forkJoin([reqRanking]).subscribe(
        (results: any) => {
          this.globalService
            .getKey("SELECTED_LANGUAGE")
            .then((lngLocal: string) => {
              results = this.translateFromApiService.translate(
                lngLocal,
                results
              );
              const groupByq = (array, key) => {
                return array.reduce((result, currentValue) => {
                  // If an array already present for key, push it to the array. Else create an array and push the object
                  (result[currentValue[key]] =
                    result[currentValue[key]] || []).push(currentValue);
                  // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
                  return result;
                }, {}); // empty object is the initial value for result object
              };

              const resultGroupedBy = groupByq(results[0][0].stats, "name");
              // console.log(resultGroupedBy, 'before reform');

              let i = 0;
              const formatArray = [];
              // tslint:disable-next-line:forin
              for (const key in resultGroupedBy) {
                const item = {
                  id: null,
                  isHeadline: null,
                  name: null,
                  array: null,
                };
                const value = resultGroupedBy[key];
                i++;
                item.id = i;
                item.name = key;
                item.isHeadline = value[0].isHeadline;
                item.array = value;
                formatArray.push(item);
              }
              results[0][0].stats = formatArray.sort((one, two) =>
                one.name > two.name ? 1 : -1
              );
            });
          this.changeRanking(results[0][0]);
        },
        async (error) => {
          if (!this.erreur) {
            if (error.status === 401) {
              this.globalService.showSessionExpiredAlertAndLogOut();
              this.erreur = true;
              await this.globalService.dismissLoader();
            }
            this.commonService.handleError(error);
            await this.globalService.dismissLoader();
          }
        }
      );
    } catch (error) {}
  }

  changeRanking(data) {
    this.ranking$.next(data);
  }

  async loadingResultsByEventId(indexStart?, offset?, event?) {
    try {
      if (!indexStart && offset) {
        this.resultsList = null;
      }

      const allSports = await this.globalService.getKey("sports");
      const sports = allSports.filter(
        (x) => x.postTypeId === Number(this.postType$.getValue())
      );
      let sport;
      if (sports.length > 0) {
        sport = sports[0];
      } else {
        sport = allSports[0];
      }
      const allEvents = sport.events;
      const events = allEvents.filter(
        (x) => x.postCategoryId === Number(this.postCategory$.getValue())
      );
      let sportEvent;
      if (events.length > 0) {
        sportEvent = events[0];
      } else {
        sportEvent = allEvents[0];
      }
      console.log("event", sportEvent, this.postCategory$.getValue());

      if (!indexStart) {
        indexStart = 0;
      }
      if (!offset) {
        offset = 10;
      }

      const bodyEvents = {
        idsportEvent: sportEvent.id,
        locale: this.staticDataService.settings.home.lgn,
        offset: indexStart,
        limit: offset,
        width: this.staticDataService.settings.match.width,
        ended: this.staticDataService.settings.match.ended,
      };

      const reqSports = await this.httpService.post("all-matches", bodyEvents);
      await forkJoin([reqSports]).subscribe(
        async (results: any) => {
          this.globalService
            .getKey("SELECTED_LANGUAGE")
            .then((lngLocal: string) => {
              results[0] = this.translateFromApiService.translate(
                lngLocal,
                results[0]
              );
              if (
                results[0].length === 0 &&
                this.matchesList.length > 0 &&
                event
              ) {
                event.target.disabled = true;
                this.globalService.presentToast("No More Data");
              }
              if (results[0].length === 0 && !event) {
                this.resultsList = [];
                this.changeResultByEventId(this.resultsList);
                return;
              } else {
                this.resultsList = this.resultsList.concat(results[0]);
                this.changeResultByEventId(this.resultsList);
              }
            });
        },
        async (error) => {
          if (!this.erreur) {
            if (error.status === 401) {
              this.globalService.showSessionExpiredAlertAndLogOut();
              this.erreur = true;
              await this.globalService.dismissLoader();
            }
            this.commonService.handleError(error);
            await this.globalService.dismissLoader();
          }
        }
      );
    } catch (error) {
      console.log(error, "error");
    }
  }

  changeResultByEventId(data) {
    this.results$.next(data);
  }

  async loadingCalendarByEventId(indexStart?, offset?, event?) {
    try {
      if (!indexStart && offset) {
        this.matchesList = null;
      }

      const allSports = await this.globalService.getKey("sports");
      const sports = allSports.filter(
        (x) => x.postTypeId === Number(this.postType$.getValue())
      );
      let sport;
      if (sports.length > 0) {
        sport = sports[0];
      } else {
        sport = allSports[0];
      }
      const allEvents = sport.events;
      const events = allEvents.filter(
        (x) => x.postCategoryId === Number(this.postCategory$.getValue())
      );
      let sportEvent;
      if (events.length > 0) {
        sportEvent = events[0];
      } else {
        sportEvent = allEvents[0];
      }
      console.log("event", sportEvent, this.postCategory$.getValue());

      if (!indexStart) {
        indexStart = 0;
      }
      if (!offset) {
        offset = 10;
      }

      const bodyCalendars = {
        idsportEvent: sportEvent.id,
        locale: this.staticDataService.settings.home.lgn,
        offset: indexStart,
        limit: offset,
        width: this.staticDataService.settings.match.width,
        ended: this.staticDataService.settings.match.notEnded,
      };

      const reqbodyCalendars = await this.httpService.post(
        "all-matches",
        bodyCalendars
      );
      await forkJoin([reqbodyCalendars]).subscribe(
        async (results: any) => {
          this.globalService
            .getKey("SELECTED_LANGUAGE")
            .then((lngLocal: string) => {
              results[0] = this.translateFromApiService.translate(
                lngLocal,
                results[0]
              );
              if (
                results[0].length === 0 &&
                this.matchesList.length > 0 &&
                event
              ) {
                event.target.disabled = true;
                this.globalService.presentToast("No More Data");
              }
              if (results[0].length === 0 && !event) {
                this.matchesList = [];
                this.changeCalendarByEventId(this.matchesList);
                return;
              } else {
                this.matchesList = this.matchesList.concat(results[0]);
                this.changeCalendarByEventId(this.matchesList);
              }
            });
        },
        async (error) => {
          if (!this.erreur) {
            if (error.status === 401) {
              this.globalService.showSessionExpiredAlertAndLogOut();
              this.erreur = true;
              await this.globalService.dismissLoader();
            }
            this.commonService.handleError(error);
            await this.globalService.dismissLoader();
          }
        }
      );
    } catch (error) {
      console.log(error, "error");
    }
  }

  changeCalendarByEventId(data) {
    this.calendar$.next(data);
  }

  async loadingLiveByEventId() {
    try {
      this.loadMatchLive();
      this.liveInterval = interval(30000);
      this.subscription = this.liveInterval.subscribe(async () => {
        this.loadMatchLive();
      });
    } catch (error) {
      console.log(error, "error");
    }
  }

  changeLiveByEventId(data) {
    this.live$.next(data);
  }

  clearInterval() {
    this.subscription.unsubscribe();
  }

  async loadMatchLive(indexStart?, offset?, event?) {
    try {
      if (!indexStart && offset) {
        this.liveList = null;
      }

      const allSports = await this.globalService.getKey("sports");
      const sports = allSports.filter(
        (x) => x.postTypeId === Number(this.postType$.getValue())
      );
      let sport;
      if (sports.length > 0) {
        sport = sports[0];
      } else {
        sport = allSports[0];
      }
      const allEvents = sport.events;
      const events = allEvents.filter(
        (x) => x.postCategoryId === Number(this.postCategory$.getValue())
      );
      let sportEvent;
      if (events.length > 0) {
        sportEvent = events[0];
      } else {
        sportEvent = allEvents[0];
      }
      console.log("event", sportEvent, this.postCategory$.getValue());

      if (!indexStart) {
        indexStart = 0;
      }
      if (!offset) {
        offset = 1000;
      }

      const bodyLiveEvents = {
        idsportEvent: sportEvent.id,
        locale: this.staticDataService.settings.home.lgn,
        offset: indexStart,
        limit: offset,
        width: this.staticDataService.settings.match.width,
        ended: this.staticDataService.settings.match.notEnded,
        started: this.staticDataService.settings.match.started,
      };

      const reqLives = await this.httpService.post(
        "all-matches",
        bodyLiveEvents
      );
      await forkJoin([reqLives]).subscribe(
        async (results: any) => {
          console.log("here", results[0]);

          this.globalService
            .getKey("SELECTED_LANGUAGE")
            .then((lngLocal: string) => {
              results[0] = this.translateFromApiService.translate(
                lngLocal,
                results[0]
              );
              if (
                results[0].length === 0 &&
                this.liveList.length > 0 &&
                sportEvent
              ) {
                event.target.disabled = true;
                this.globalService.presentToast("No More Data");
              }
              if (results[0].length === 0 && !event) {
                this.liveList = [];
                this.changeLiveByEventId(this.liveList);
                return;
              } else {
                this.liveList.forEach((elementInLocal) => {
                  results[0] = results[0].filter(
                    (item) => item.id !== elementInLocal.id
                  );
                });
                this.liveList = this.liveList.concat(results[0]);
                this.changeLiveByEventId(this.liveList);
              }
            });
        },
        async (error) => {
          if (!this.erreur) {
            if (error.status === 401) {
              this.globalService.showSessionExpiredAlertAndLogOut();
              this.erreur = true;
              await this.globalService.dismissLoader();
            }
            this.commonService.handleError(error);
            await this.globalService.dismissLoader();
          }
        }
      );
    } catch (error) {
      console.log(error, "error");
    }
  }
}
