import { Config, ModalController, NavParams } from "@ionic/angular";

import { CommonService } from "../../providers/commonService/common-service.service";
import { Component } from "@angular/core";
import { GlobalService } from "../../providers/global/global.service";
import { HttpService } from "../../providers/httpService/http.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "page-notification-filter",
  templateUrl: "notification-filter.html",
  styleUrls: ["./notification-filter.scss"],
})
export class NotificationFilterPage {
  ios: boolean;
  bodyNotifications: any;
  notifications: { name: string; bodyId: string; isChecked: boolean }[] = [];
  constructor(
    private config: Config,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public globalService: GlobalService,
    private httpService: HttpService,
    public translate: TranslateService,
    public commonService: CommonService
  ) {}

  ionViewWillEnter() {
    this.ios = this.config.get("mode") === `ios`;
    const excludedNotificationsNames = this.navParams.get(
      "excludedNotifications"
    );
    console.log(excludedNotificationsNames, "excludedNotificationsNames");
    this.notifications = excludedNotificationsNames;
  }

  selectAll(check: boolean) {
    // set all to checked or unchecked
    this.notifications.forEach((notification) => {
      notification.isChecked = check;
    });
  }

  async applyFilters() {
    const excludedNotificationNames = this.notifications
      .filter((c) => !c.isChecked)
      .map((c) => c.name);
    this.bodyNotifications = {
      locale: "fr",
    };
    this.notifications.forEach((element) => {
      this.bodyNotifications[element.bodyId] = element.isChecked;
    });
    await this.globalService.showLoader(
      this.translate.instant("common.LOADING")
    );
    (
      await this.httpService.put("updateProfile", this.bodyNotifications)
    ).subscribe(
      async (data: any) => {
        await this.globalService.dismissLoader();
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }        await this.globalService.dismissLoader();
        this.commonService.handleError(error);
      }
    );

    this.dismiss(excludedNotificationNames);
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
}
